/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.mutation({
    query: ({
      topicName,
      topicId,
      topicDetails = '',
      topicOrder,
      isCommonTopic,
    }) => {
      return {
        url: `report-summary-topics/${topicId}`,
        method: 'PUT',
        body: {
          topicName: topicName,
          topicDetails: topicDetails,
          order: topicOrder,
          isCommonTopic: isCommonTopic,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      };
    },
    invalidatesTags: ['report-summary-topics'],
  });
