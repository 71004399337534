/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.mutation({
    query: ({
      topicName,
      topicDetails,
      feature,
      topicOrder,
      isCommonTopic,
    }) => ({
      url: `report-summary-topics`,
      method: 'POST',
      body: {
        topicName: topicName,
        topicDetails: topicDetails,
        feature: feature,
        order: topicOrder,
        isCommonTopic: isCommonTopic,
      },
      'Content-Type': 'application/json',
    }),
    invalidatesTags: ['report-summary-topics'],
  });
